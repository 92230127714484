<template>
  <div class="full">
    <nav class="order_nav d_f ali_c">
      <img src="../img/black_back.png" alt="" class="cur_p" @click="$router.go(-1)">
      <span>退款进度</span>
    </nav>
    <section class="ed_content">
      <div class="card">
        <div class="c_title bb">
          退款信息
        </div>
        <div class="edc_content">
          <div class="item d_f ali_c">
            <div class="title">商家名称</div>
            <div class="hotelName">{{$route.query.hotelName}}</div>
          </div>
          <div class="item d_f ali_c">
            <div class="title">订单号码</div>
            <div>{{$route.query.orderNumber}}</div>
          </div>
          <div class="item d_f ali_c">
            <div class="title">退款金额</div>
            <div>￥{{info.orderRefund.trueRefundAmount ? (info.orderRefund.trueRefundAmount/100).toFixed(2) : 0}}</div>
          </div>
          <div class="item d_f ali_c">
            <div class="title">退款原因</div>
            <div>{{info.orderRefund.cause}}</div>
          </div>
          <div class="item d_f ali_c">
            <div class="title">退款状态</div>
            <div class="grey" v-if="info.orderRefund.status == 1">运营审核中</div>
            <div class="grey" v-if="info.orderRefund.status == 2">退票中</div>
            <div class="grey" v-if="info.orderRefund.status == 3">退票失败</div>
            <div class="grey" v-if="info.orderRefund.status == 4">退款中</div>
            <div class="orange" v-if="info.orderRefund.status == 5">退款失败</div>
            <div class="green" v-if="info.orderRefund.status == 6">退款成功</div>
            <div class="grey" v-if="info.orderRefund.status == 7">财务审核中</div>
            <div class="orange" v-if="info.orderRefund.status == 8">退款失败</div>
            <!--<div class="orange" v-if="info.orderRefund.status == 8">审核不通过</div>-->
          </div>
        </div>
      </div>
      <div class="card">
        <div class="c_title">
          退款进度
        </div>
        <div class="edc_content">
          <van-steps direction="vertical" :active="active"  active-color="#1DDF50">
            <van-step>
              <div>你的退款申请已受理</div>
              <p>{{info.orderRefund.creatorTime}}</p>
            </van-step>
            <van-step>
              <div>{{text}}</div>
              <p>{{info.orderRefund.creatorTime}}</p>
            </van-step>
            <template v-if="info.orderRefund.status === 6 || info.orderRefund.status === 8 || info.orderRefund.status === 5">
              <van-step v-if="info.orderRefund.status === 6">
                <div>商家已退款，预计1-2个工作日到账</div>
                <p>{{info.orderRefund.refundNoticeTime}}</p>
              </van-step>
              <van-step v-if="info.orderRefund.status === 5">
                <div>商家拒绝了您的退款申请</div>
                <p>{{info.orderRefund.refundNoticeTime}}</p>
              </van-step>
              <van-step v-if="info.orderRefund.status === 8">
                <div>商家拒绝了您的退款申请</div>
                <p>{{info.orderRefund.refundNoticeTime}}</p>
              </van-step>
            </template>
            <van-step v-else>
              <div>等待商家退款</div>
              <p>{{$formatDate(new Date(),"yyyy-MM-dd hh:mm:ss")}}</p>
            </van-step>
          </van-steps>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import apis from '../utils/apis'
  import { Steps, Step} from 'vant'
  export default {
    name: "refundProgress",
    components:{
      'van-steps': Steps,
      'van-step': Step,
    },
    data() {
      return {
        info:{orderRefund:{}},
        active:1,
        text:'你的退款申请审核中...'
      }
    },
    created(){
      let me = this;
      me.$get({
        url:apis.findreFundByOrderNumber,
        params: {
          token:me.$global.token,
          orderNumber:me.$route.query.orderNumber
        }
      }).then(res => {
        me.info = res;
        if(res.orderRefund.status === 0){
          me.active = 0
        }
        if(res.orderRefund.status === 1){
          me.active = 1
        }
        if(res.orderRefund.status === 6){
          this.text = '你的退款申请已通过';
          me.active = 2
        }
        if(res.orderRefund.status === 5){
          this.text = '你的退款申请已通过';
          me.active = 2
        }
        if(res.orderRefund.status === 8){
          this.text = '你的退款申请未通过';
          me.active = 2
        }
      });
    },
    methods: {
      exit(){
        // this.$destroy('KeepAlive')
        sessionStorage.clear();
        sessionStorage.setItem('LOGIN_STATUS',2);
        sessionStorage.setItem('token',this.$global.token);
        this.$router.go(-1)
      },
    }
  }
</script>

<style scoped lang="scss">
  .hotelName{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
  }
  .card{
    .edc_content{
      .item{
        .grey{
          color: #A0A0A0;
        }
        .green{
          color: #16C173;
        }
        .orange{
          color: #FF6701;
        }
        .title{
          color: #909090;
          width: 73px;
        }
        margin-top: 10px;
        height:11px;
        font-size:12px;
        font-weight:500;
        color:rgba(62,62,62,1);
      }
      padding: 5px 24px 15px 24px;
    }
    .c_title{
      font-size:13px;
      font-weight:500;
      color:rgba(62,62,62,1);
      line-height: 36px;
      padding: 0 24px;
      &.bb{
        border-bottom: 1px solid #D0D0D0;
      }
    }
    background:rgba(255,255,255,1);
    border-radius:3px;
    margin-bottom: 10px;
  }
  .order_nav{
    background: white;
    span{
      color: #3E3E3E !important;
    }
  }
  .ed_content{
    padding: 22px 21px;
  }
  .full{
    background-color: #F0F0F0;
    box-sizing: border-box;
  }
</style>

